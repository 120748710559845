import React, { useEffect, useRef, useState } from 'react';
import ReCaptchaV2 from 'react-google-recaptcha';
import { logging } from '../../../SharedLib';
import { registerUser } from '../../../SharedLib/Util/CognitoUtil';
import { RE_CAPTCHA_V2_SITE_KEY } from '../../../SharedLib/Cfg/GoogleCfg';
import styles from './SignUpForm.module.scss';
import CustomButton from '../../CustomButton/CustomButton';
import { nameValid, PWD_RULES, validateEmail } from '../../../SharedLib/Util/ValidationUtil';
import { LoginView } from '../LoginPanel/LoginPanel';
import { PasswordValidationRule } from '../../../SharedLib/Util/Interfaces';
import { messageForError } from '../../../SharedLib/Util/ErrorUtil';
import { getClassroomInvitationBrowserStorageMeta } from '../../ClassroomInvitation/ClassroomInvitationUtil';
import { getQueryParamNamed } from '../../../SharedLib/Util/QueryParamsUtil';

const RE_CAPTCHA_ENABLED = true;

export interface ICaptchaTokenType {
  value: string,
  origin: 'ReCaptchaV2' | 'ReCaptchaV3',
  action: string,
}

export interface ISignUpFormProps {
  email: string,
  setEmail: React.Dispatch<React.SetStateAction<string>>,
  password: string,
  setPassword: React.Dispatch<React.SetStateAction<string>>,
  setView: React.Dispatch<React.SetStateAction<LoginView>>,
  setUsername: React.Dispatch<React.SetStateAction<string>>,
}

const SignUpForm: React.FC<ISignUpFormProps> = ({ email, setEmail, password, setPassword, setView, setUsername }) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [emailConsent, setEmailConsent] = useState(true);
  const [emailConsentDisabled, setEmailConsentDisabled] = useState(false);
  const [loading, setLoading] = useState(false);

  const reCaptchaV2Ref = useRef<ReCaptchaV2 | null>();
  const [captchaToken, setCaptchaToken] = useState<ICaptchaTokenType>({
    value: '',
    origin: 'ReCaptchaV2',
    action: 'signUpUser'
  });

  const [error, setError] = useState('');

  useEffect(() => {
    const classroomInvitation = getClassroomInvitationBrowserStorageMeta();

    if (
      classroomInvitation !== null
      && classroomInvitation.flow === 'signup'
      && classroomInvitation.details.invitedMember.canRegister
      && classroomInvitation.details.invitedMember.email
    ) {
      setEmail(classroomInvitation.details.invitedMember.email);

      setEmailConsent(false);
      setEmailConsentDisabled(true);

      if (classroomInvitation.details.invitedMember.name) {
        const nameChunks = classroomInvitation.details.invitedMember.name.split(' ');

        if (nameChunks.length > 0) {
          setFirstName(nameChunks[0]);

          if (nameChunks.length > 1) {
            setLastName(nameChunks[1]);
          }
        }
      }
    }
  }, []);

  useEffect(() => {
    const emailUrlQueryParam = getQueryParamNamed('email');

    if (emailUrlQueryParam && emailUrlQueryParam.value) {
      setEmail(decodeURIComponent(emailUrlQueryParam.value));
    }
  }, []);

  function validatePassword(password: string): { valid: boolean, message: string } {
    const rulesNotPassed = [...PWD_RULES, {
      regexp: /^[\S]+.*[\S]+$/,
      title: 'no trailing whitespace',
    }].filter(
      (rule: PasswordValidationRule) => !rule.regexp.test(password)
    );

    if (rulesNotPassed.length) {
      const rule = rulesNotPassed[0].title.toLowerCase();

      return {
        valid: false,
        message: rule.includes('8') || rule.includes('whitespace') ? `Password requires ${rule}` : `Password requires at least ${rule}`,
      };
    }

    return {
      valid: true,
      message: '',
    };
  }

  function isInputValid(): boolean {
    if (!email || !password || !firstName || !lastName) {
      setError('Please fill in all fields');
      return false;
    }

    if (!firstName) {
      setError('Please fill in your first name');
      return false;
    }

    if (!lastName) {
      setError('Please fill in your last name');
      return false;
    }

    if (!nameValid(firstName) || !nameValid(lastName)) {
      setError('Add a name that only contains letters - and no special characters.');
      return false;
    }

    if (!validateEmail(email)) {
      setError('Please enter a valid email address');
      return false;
    }

    const passwordValidation = validatePassword(password);
    if (!passwordValidation.valid) {
      setError(passwordValidation.message);
      return false;
    }


    return true;
  }

  async function handleSignup(e: React.FormEvent) {
    e.preventDefault();
    setLoading(true);

    if (!isInputValid()) {
      setLoading(false);
      return;
    }

    await reCaptcha();

    try {
      const { response, username } = await registerUser({
        email,
        password,
        firstName,
        lastName,
        captchaToken,
        emailConsent: emailConsent,
        user_origin: 'profile',
        user_origin_signed: '',
      });

      logging.logDebug('SignUpForm -> handleSignup -> response: ', response);

      if ('code' in response) {
        return setError(
          messageForError({
            code: response.code,
            msg: response.msg,
          }).msg
        );
      }

      setUsername(username);

      return setView(LoginView.VERIFICATION_PENDING);
    }

    catch (error) {
      console.log('signup error', error);
      if (error instanceof Error) {
        setError(messageForError({
          code: error.name,
          msg: error.message,
        }).msg);
      }
      return;
    }

    finally {
      setLoading(false);
    }
  }

  async function reCaptcha() {
    const resCaptchaToken = captchaToken;
    let res_captcha_exc;

    if (RE_CAPTCHA_ENABLED) {
      if (
        !reCaptchaV2Ref.current
      ) {

        setError('Google ReCAPTCHA Error: Failed to retrieve ReCaptchaV2 reference');
        return;
      }

      const re_captcha_v2_token = await reCaptchaV2Ref.current
        .executeAsync()
        .catch((err) => {
          logging.logError(
            'this.reCaptchaV2Ref -> executeAsync().catch -> err: ',
            err
          );

          setError('Google ReCAPTCHA Error: Failed to execute ReCaptchaV2');
          return;
        });

      reCaptchaV2Ref.current.reset();

      logging.logDebug(
        'LoginModal -> submit -> resCaptchaToken: ',
        resCaptchaToken
      );
      logging.logDebug(
        'LoginModal -> submit -> res_captcha_exc: ',
        res_captcha_exc
      );

      if (!re_captcha_v2_token) {


        setError('Google ReCAPTCHA Error: Failed to retrieve ReCaptchaV2 token');

        return;
      }

      resCaptchaToken.value = re_captcha_v2_token;

      setCaptchaToken(resCaptchaToken);
    }
  }

  return (
    <div className={styles.signup_form_container}>
      <form className={styles.signup_form} onSubmit={handleSignup}>
        <div className={styles.signup_inputs}>
          <input
            type="text"
            value={email}
            onChange={e => setEmail(e.target.value)}
            placeholder="email"
            className={styles.signup_input}
          />
          <input
            type="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
            placeholder="password"
            className={styles.signup_input}
          />
          <input
            type="text"
            value={firstName}
            onChange={e => setFirstName(e.target.value)}
            placeholder="first name"
            className={styles.signup_input}
          />
          <input
            type="text"
            value={lastName}
            onChange={e => setLastName(e.target.value)}
            placeholder="last name"
            className={styles.signup_input}
          />
        </div>
        <div className={styles.error_text}>{error}</div>
        <div className={styles.signup_buttons}>
          <CustomButton loading={loading} height={'40px'} type='submit' className={styles.signup_button}>Sign Up</CustomButton>
        </div>
        <div className={styles.text + ' ' + styles.term_text}>By signing up you agree to our {' '}
          <a
            href='https://www.w3schools.com/about/about_copyright.asp'
            target='_blank'
            rel='noopener noreferrer'
          >
            Terms of Service {' '}
          </a>
          and {' '}
          <a
            href='https://www.w3schools.com/about/about_privacy.asp'
            target='_blank'
            rel='noopener noreferrer'
          >
            Privacy Policy
          </a>
        </div>
        <div className={styles.email_consent_checkbox}>
          <input type="checkbox" checked={emailConsent} disabled={emailConsentDisabled} onChange={() => setEmailConsent((prev) => !prev)} />
          <span className={styles.text}>Email me with news and updates</span>
        </div>
      </form>

      <ReCaptchaV2
        ref={(reCaptchaV2RefPassed) => {
          reCaptchaV2Ref.current = reCaptchaV2RefPassed;
        }}
        sitekey={RE_CAPTCHA_V2_SITE_KEY}
        size='invisible'
      />
    </div>
  );
};

export default SignUpForm;
