import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { getBillingApiUrl } from '../utils/get-public-profile-base-url';
import { post } from '../utils/utils';
import { getLoginUrl, getPathfinderUrl } from '../SharedLib/Util/EnvironmentUtil';
import { getProfileBaseUrl } from '../SharedLib/Util/InternalProjects/ProfileUtil';
import { fetchProfile } from '../SharedLib';
import { authUtil } from '../SharedLib/Util/AuthUtil';

interface IFeature {
  text: string | JSX.Element,
  helpText: string,
}
interface ISubscriptionPlanType {
  id: 'free' | 'plus_monthly' | 'plus_yearly' | 'lifetime' | 'classroom',
  planType:  'FREE' | 'PLUS' | 'ACADEMY' | 'LIFETIME',
  price: string,
  period: string,
  description: string,
  features: IFeature[],
  isSelected: boolean,
  isVisible: boolean,
}
const DEFAULT_SUBSCRIPTION_PLANS: ISubscriptionPlanType[] = [
  {
    id: 'plus_monthly',
    planType: 'PLUS',
    price: '$14.99',
    period: '/Month',
    description: 'This is for you that want to learn and reach your goals faster. Work with 18 different programming languages, ad free experience',
    features: [
      {text: 'Ad-free experience', helpText: ''},
      {text: 'Build and host 15 static websites', helpText: ''},
      {text: 'Build and practise with 1 full stack server', helpText: ''},
      {text: '50000 credits/month', helpText: 'Access various AI features like W3Schools kAI coding tutor and intreview prep.'},
      //{text: 'Work with 18 different languages and frameworks', helpText: ''},
      //{text: 'Choose between 75+ templates', helpText: ''},
      {text: '30% discount on all certifications', helpText: ''},
      {text: 'Priority Support', helpText: ''},
      {text: 'Cancel anytime', helpText: ''},
      // {text: 'By choosing this plan W3Schools is able to support  10  learners/ month', helpText: ''}
     
    ],
    isSelected: true,
    isVisible: true,
  },
  {
    id: 'plus_yearly',
    planType: 'PLUS',
    price: '$149.99',
    period: '/Year',
    description: 'This is for you that want to learn and reach your goals faster. Work with 18 different programming languages, ad free experience',
    features: [
      /*{text: 'Ad-free experience', helpText: ''},
      {text: 'Build and host 15 static websites', helpText: ''},
      {text: 'Build and practise with 1 full stack server', helpText: ''},
      {text: '50000 credits/month', helpText: 'Access various AI features like W3Schools kAI coding tutor and intreview prep.'},
      //{text: 'Work with 18 different languages and frameworks', helpText: ''},
      //{text: 'Choose between 75+ templates', helpText: ''},
      {text: '30% discount on all certifications', helpText: ''},
      {text: 'Priority Support', helpText: ''},
      {text: 'Cancel anytime', helpText: ''},
      // {text: 'By choosing this plan W3Schools is able to support  10  learners/ month', helpText: ''}
      */
    ],
    isSelected: false,
    isVisible: false,
  },
  {
    id: 'free',
    planType: 'FREE',
    price: '$0',
    period: '/Forever',
    description: 'This is for you that are beginning to explore coding and web development',
    features: [
      /*{text: 'Build and host 1 static website', helpText: ''},
      //{text: 'Limited job matching', helpText: ''},
      {text: '100 credits/month', helpText: 'Access various AI features like W3Schools kAI coding tutor and intreview prep.'},
      {text: 'No support', helpText: ''}
      */
    ],
    isSelected: false,
    isVisible: true,
  },
  {
    id: 'classroom',
    planType: 'ACADEMY',
    price: '',
    period: 'Contact sales',
    description: 'Provides educational institutions and businesses with interactive courses, coding challenges, collaboration,  progress tracking and certifications',
    features: [
      /* {text: 'Get lifetime access to W3Schools Plus subscription', helpText: ''},
      {text: 'Access to all current and future exams and certifications', helpText: ''},
      {text: 'Access 50+ professional certifications', helpText: ''},
      {text: 'Complete the exams and certifications in your own order and pace', helpText: ''},
      {text: 'Verify your skills with industry recognized certifications', helpText: ''},
      {text: '50000 credits/month', helpText: 'Access various AI features like W3Schools kAI coding tutor and intreview prep.'},
      // {text: 'By choosing this plan W3Schools is able to support  380 free learners', helpText: ''}
      */
    ],
    isSelected: false,
    isVisible: true,
  }
  /*{
    id: 'lifetime',
    planType: 'LIFETIME',
    price: '$695',
    period: '/Lifetime',
    description: 'For you who want to become certified in any programming language you want',
    features: [
      {text: 'Get lifetime access to W3Schools Plus subscription', helpText: ''},
      {text: 'Access to all current and future exams and certifications', helpText: ''},
      {text: 'Access 50+ professional certifications', helpText: ''},
      {text: 'Complete the exams and certifications in your own order and pace', helpText: ''},
      {text: 'Verify your skills with industry recognized certifications', helpText: ''},
      {text: '50000 credits/month', helpText: 'Access various AI features like W3Schools kAI coding tutor and intreview prep.'},
      // {text: 'By choosing this plan W3Schools is able to support  380 free learners', helpText: ''}
    ],
    isSelected: false,
    isVisible: true,
  },*/
];

export type PlanSelectionContextStateType = {
  isLoading: boolean,
  plans: ISubscriptionPlanType[],
  setSelectedPlanId: React.Dispatch<React.SetStateAction<string>>,
  isAnnual: boolean,
  toggleAnnual: () => void,
  purchasePlan: () => void,
  displayToast: boolean,
  setDisplayToast: React.Dispatch<React.SetStateAction<boolean>>,
  toastDetails: {
    title: string,
    message: string,
    type: 'success' | 'warning' | 'danger' | 'info',
  },
};

const DEFAULT_STATE: PlanSelectionContextStateType = {
  isLoading: false,
  plans: DEFAULT_SUBSCRIPTION_PLANS,
  setSelectedPlanId: () => {
    //
  },
  isAnnual: false,
  toggleAnnual: () => {
    //
  },
  purchasePlan: () => {
    //
  },
  displayToast: false,
  setDisplayToast: () => {
    //
  },
  toastDetails: {
    title: '',
    message: '',
    type: 'info',
  },
};

const PlanSelectionStateContext = createContext<PlanSelectionContextStateType>(DEFAULT_STATE);

const PlanSelectionStateProvider = ({ children }: React.PropsWithChildren<any>) => {
  const [isLoading, setLoading] = useState(false);
  const [isAnnual, setIsAnnual] = useState(false);
  const [displayToast, setDisplayToast] = useState(false);
  const [toastDetails, setToastDetails] = useState(DEFAULT_STATE.toastDetails);
  const [selectedPlanId, setSelectedPlanId] = useState<string>('plus_monthly');


  useEffect(() => {
    const checkUserLoggedIn = async () => {
      const userIsLoggedIn = await authUtil.userIsLoggedIn({
        context: 'planSelectionPage -> checkLoginStatus',
      });
      if (!userIsLoggedIn) {
        window.location.href = getLoginUrl();
      }
    };

    checkUserLoggedIn();
  }, []);

  const plans = DEFAULT_SUBSCRIPTION_PLANS.map(plan => ({
    ...plan,
    isSelected: plan.id === selectedPlanId,
    isVisible: plan.id === 'plus_monthly' ? !isAnnual :
      plan.id === 'plus_yearly' ? isAnnual :
        plan.isVisible,
  }));

  const toggleAnnual = () => {
    setIsAnnual(!isAnnual);
    setSelectedPlanId(!isAnnual ? 'plus_yearly' : 'plus_monthly');
  };

  const purchasePlan = useCallback(async () => {
    try {
      setLoading(true);
      if (selectedPlanId === 'free') {
        await fetchProfile({
          method: 'PATCH',
          url: `${getProfileBaseUrl()}/api/user/unset-is-first-login`,
        });

        return window.location.href = getPathfinderUrl();
      }
      const url = `https://${getBillingApiUrl()}/create-checkout-session`;

      //fetch the selected plan
      const payload = {
        product: selectedPlanId,
      };

      const res = await post<{ url: string }>(url, payload);
      window.location.href = res.url;
    } catch (error: any) {
      setDisplayToast(true);
      setToastDetails({
        title: 'Error',
        message: error.message,
        type: 'danger',
      });
    } finally {
      setLoading(false);
    }
    
  }, [selectedPlanId]);

  const value: PlanSelectionContextStateType = useMemo(
    () => ({
      isLoading,
      plans,
      setSelectedPlanId,
      isAnnual,
      toggleAnnual,
      purchasePlan,
      displayToast,
      setDisplayToast,
      toastDetails
    }),
    [isLoading, plans, setSelectedPlanId, isAnnual, toggleAnnual, purchasePlan, displayToast, setDisplayToast, toastDetails],
  );

  return <PlanSelectionStateContext.Provider value={value}>{children}</PlanSelectionStateContext.Provider>;
};

export { PlanSelectionStateProvider, PlanSelectionStateContext };
